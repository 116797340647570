/* eslint-disable */
<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link class="brand-logo">

                <h2 class="brand-text text-primary ml-1" />
            </b-link>
            <b-col
lg="8"
class="d-none d-lg-flex align-items-center p-5"
style="background-color: black;"
>
                <div
                    class="w-100 d-lg-flex align-items-center justify-content-center px-5"
                >
                    <b-img
                        fluid
                        alt="Login V2"
                        :src="imgUrl"
                        style="width:400px;"
                    />
                </div>
            </b-col>
            <b-col
lg="4"
class="d-flex align-items-center auth-bg px-2 p-lg-5"
>
                <b-col
sm="8"
md="6"
lg="12"
class="px-xl-2 mx-auto"
>
                    <b-card-title
title-tag="h2"
class="font-weight-bold mb-1"
>
                        Forgot Password
                    </b-card-title>
                    <b-card-text class="mb-2">
                        <!-- Please sign-in to your account and start the adventure -->
                    </b-card-text>

                    <!-- form -->
                    <validation-observer
                        ref="loginValidation"
                    >
                        <b-form
                            class="auth-login-form mt-2"
                            @submit.prevent
                        >
                            <!-- email -->
                            <b-form-group label="Set New Password">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Password"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="
                                            errors.length > 0
                                                ? 'is-invalid'
                                                : null
                                        "
                                    >
                                        <b-form-input
                                            id="login-password"
                                            v-model="password"
                                            :state="
                                                errors.length > 0 ? false : null
                                            "
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="login-password"
                                            placeholder="Set new password"
                                        />
                                        <!-- <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="
                                                    togglePasswordVisibility
                                                "
                                            />
                                        </b-input-group-append> -->
                                    </b-input-group>
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-form-group label="Confirm Password">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Confirm Password"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="
                                            errors.length > 0
                                                ? 'is-invalid'
                                                : null
                                        "
                                    >
                                        <b-form-input
                                            id="confirm-password"
                                            v-model="confirmPassword"
                                            :state="
                                                errors.length > 0 ? false : null
                                            "
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="confirm-password"
                                            placeholder="Confirm password"
                                        />
                                        <!-- <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="
                                                    togglePasswordVisibility
                                                "
                                            />
                                        </b-input-group-append> -->
                                    </b-input-group>
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- submit buttons -->
                            <b-row>
                                <b-col cols="4">
                                <b-link
                                    class="btn btn-dark btn-block"
                                    :to="{
                                        name: 'login',
                                    }"
                                >
                                    Back
                                </b-link>
                                </b-col>
                                <b-col cols="8">
                                <b-button
                                    type="submit"
                                    variant="primary"
                                    block
                                    :disabled="!validate"
                                    @click="validationForm"
                                >
                                    Submit
                                </b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
    components: {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        // BInputGroupAppend,
        // BInputGroup,
        // BFormCheckbox,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton,

        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            status: '',
            password: '',
            confirmPassword: '',
            userEmail: '',
            sideImg: require('@/assets/images/logo/__logo.svg'),
            required,
            email,
            emailSent: false,
        };
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password'
                ? 'EyeIcon'
                : 'EyeOffIcon';
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
                return this.sideImg;
            }
            return this.sideImg;
        },
        validate() {
            return this.password.trim().length > 0 && this.confirmPassword.trim().length > 0 && this.password === this.confirmPassword;
        },
    },
    methods: {
        validationForm() {
            this.$refs.loginValidation.validate().then((success) => {
                if (success) {
                    this.resetPassword();
                }
            });
        },
        registerUser() {
            this.$router.push('/register').catch(() => {});
        },
        resetPassword() {
            this.$helper.showLoading();
            const { key } = this.$route.query;
            this.login_error = '';
            this.$axios
                .post(`/reset-password`, {
                    key,
                    password: this.password,
                })
                .then(resp => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: resp.data.message,
                            icon: resp.data.status ? 'CheckIcon' : 'AlertCircleIcon',
                            variant: resp.data.status ? 'success' : 'danger',
                        },
                    });
                    const vm = this;
                    if (resp.data.status) {
                        setTimeout(() => {
                            vm.$router.replace('/');
                        }, 500);
                    }
                })
                .catch(error => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                    console.error(error);
                });
        },
    },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
