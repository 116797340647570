var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-primary ml-1"})]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",staticStyle:{"background-color":"black"},attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{staticStyle:{"width":"400px"},attrs:{"fluid":"","alt":"Login V2","src":_vm.imgUrl}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Forgot Password ")]),_c('b-card-text',{staticClass:"mb-2"}),_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Set New Password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0
                                                ? 'is-invalid'
                                                : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"Set new password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Confirm Password"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0
                                                ? 'is-invalid'
                                                : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"confirm-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"confirm-password","placeholder":"Confirm password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-link',{staticClass:"btn btn-dark btn-block",attrs:{"to":{
                                        name: 'login',
                                    }}},[_vm._v(" Back ")])],1),_c('b-col',{attrs:{"cols":"8"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":!_vm.validate},on:{"click":_vm.validationForm}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }